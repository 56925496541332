<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { useSearchStore } from '~/stores/search'
import type { LocationSuggestion, Search } from '@/types'

const props = defineProps({
  initialSearch: {
    type: Object as PropType<Search>,
    required: false,
  },
})

const emit = defineEmits(['closeModal'])

const route = useRoute()
const searchStore = useSearchStore()
const { search, selectedCurrency } = storeToRefs(searchStore)

// BUY OR RENT
declare interface Option {
  name: string
  value: 'buy' | 'rent'
}
const options: Option[] = [
  { name: 'Buy', value: 'buy' },
  { name: 'Rent', value: 'rent' },
]

const propertyType = ref(props.initialSearch?.buyRent === 'rent'
  ? options[1]
  : options[0])

// LOCATION
const locations: Ref<LocationSuggestion[]> = ref([])

const query = ref('')

const selectedLocation: Ref<LocationSuggestion | null> = ref(props.initialSearch?.location
  ? {
    label: props.initialSearch.location.text,
    value: {
      text: props.initialSearch.location.text,
      n: props.initialSearch.location.n,
      s: props.initialSearch.location.s,
      e: props.initialSearch.location.e,
      w: props.initialSearch.location.w,
    },
  }
  : null,
)

// this is how we update the search box text to say "searching the map..."
// watch search
searchStore.$subscribe((mutation, state) => {
  if (state.search.mapSearch) {
    selectedLocation.value = {
      label: 'Searching the map...',
      value: {
        text: 'Searching the map...',
        n: state.search.location?.n ?? 52.4,
        s: state.search.location?.s ?? 51.5,
        e: state.search.location?.e ?? -1.0,
        w: state.search.location?.w ?? -2.5,
      },
    }
  }
})

const filteredLocations = computed(() =>
  query.value === ''
    ? locations.value
    : locations.value.filter((location) => {
      return location.label.toLowerCase().includes(query.value.toLowerCase())
    }),
)

// this function gets location suggestions from the maptiler api
const { getLocationSuggestions } = useMapTiler()

// this function runs on any input of the location combobox
const getPlaces = async () => {
  locations.value = await getLocationSuggestions(query.value)
}

// currency options
const { currencyOptionsExport } = usePrice()
const currencyOptions: Ref<SelectOption[]> = ref(currencyOptionsExport())

const usersSelectedCurrency: Ref<SelectOption> = ref(currencyOptions.value.find(option => option.id === selectedCurrency.value.id) ?? currencyOptions.value[0])

// Watch the selected currency and update the store when it changes
watch(usersSelectedCurrency, () => {
  searchStore.setCurrency(usersSelectedCurrency.value.id)
})

// PRICE OPTIONS MIN AND MAX
interface SelectOption {
  id: any
  text: string
}
const rentMinPriceOptions: ComputedRef<SelectOption[]> = computed(() => [
  { id: '0', text: 'No Min' },
  { id: '1000', text: `${selectedCurrency.value.symbol}1,000pcm` },
  { id: '1250', text: `${selectedCurrency.value.symbol}1,250pcm` },
  { id: '1500', text: `${selectedCurrency.value.symbol}1,500pcm` },
  { id: '2000', text: `${selectedCurrency.value.symbol}2,000pcm` },
  { id: '2500', text: `${selectedCurrency.value.symbol}2,500pcm` },
  { id: '3000', text: `${selectedCurrency.value.symbol}3,000pcm` },
])
const buyMinPriceOptions: ComputedRef<SelectOption[]> = computed(() => [
  { id: '0', text: 'No Min' },
  { id: '250000', text: `${selectedCurrency.value.symbol}250k` },
  { id: '300000', text: `${selectedCurrency.value.symbol}300k` },
  { id: '400000', text: `${selectedCurrency.value.symbol}400k` },
  { id: '500000', text: `${selectedCurrency.value.symbol}500k` },
  { id: '600000', text: `${selectedCurrency.value.symbol}600k` },
  { id: '700000', text: `${selectedCurrency.value.symbol}700k` },
  { id: '800000', text: `${selectedCurrency.value.symbol}800k` },
  { id: '900000', text: `${selectedCurrency.value.symbol}900k` },
  { id: '1000000', text: `${selectedCurrency.value.symbol}1m` },
  { id: '1250000', text: `${selectedCurrency.value.symbol}1.25m` },
  { id: '1500000', text: `${selectedCurrency.value.symbol}1.5m` },
  { id: '1750000', text: `${selectedCurrency.value.symbol}1.75m` },
  { id: '2000000', text: `${selectedCurrency.value.symbol}2m` },
  { id: '2500000', text: `${selectedCurrency.value.symbol}2.5m` },
  { id: '3000000', text: `${selectedCurrency.value.symbol}3m` },
  { id: '4000000', text: `${selectedCurrency.value.symbol}4m` },
  { id: '5000000', text: `${selectedCurrency.value.symbol}5m` },
])
const rentMaxPriceOptions: ComputedRef<SelectOption[]> = computed(() => [
  { id: '1250', text: `${selectedCurrency.value.symbol}1,250pcm` },
  { id: '1500', text: `${selectedCurrency.value.symbol}1,500pcm` },
  { id: '2000', text: `${selectedCurrency.value.symbol}2,000pcm` },
  { id: '2500', text: `${selectedCurrency.value.symbol}2,500pcm` },
  { id: '3000', text: `${selectedCurrency.value.symbol}3,000pcm` },
  { id: '99999999', text: 'No Max' },
])
const buyMaxPriceOptions: ComputedRef<SelectOption[]> = computed(() => [
  { id: '250000', text: `${selectedCurrency.value.symbol}250k` },
  { id: '300000', text: `${selectedCurrency.value.symbol}300k` },
  { id: '400000', text: `${selectedCurrency.value.symbol}400k` },
  { id: '500000', text: `${selectedCurrency.value.symbol}500k` },
  { id: '600000', text: `${selectedCurrency.value.symbol}600k` },
  { id: '700000', text: `${selectedCurrency.value.symbol}700k` },
  { id: '800000', text: `${selectedCurrency.value.symbol}800k` },
  { id: '900000', text: `${selectedCurrency.value.symbol}900k` },
  { id: '1000000', text: `${selectedCurrency.value.symbol}1m` },
  { id: '1250000', text: `${selectedCurrency.value.symbol}1.25m` },
  { id: '1500000', text: `${selectedCurrency.value.symbol}1.5m` },
  { id: '1750000', text: `${selectedCurrency.value.symbol}1.75m` },
  { id: '2000000', text: `${selectedCurrency.value.symbol}2m` },
  { id: '2500000', text: `${selectedCurrency.value.symbol}2.5m` },
  { id: '3000000', text: `${selectedCurrency.value.symbol}3m` },
  { id: '4000000', text: `${selectedCurrency.value.symbol}4m` },
  { id: '5000000', text: `${selectedCurrency.value.symbol}5m` },
  { id: '99999999', text: 'No Max' },
])

// sort order options
const sortOrderOptions: Ref<SelectOption[]> = ref([
  { id: 'closest', text: 'Closest' },
  { id: 'price.amount desc', text: 'Highest Price First' },
  { id: 'price.amount asc', text: 'Lowest Price First' },
  { id: 'uploaded desc', text: 'Most Recent' },
])

const currentSearchType = computed(() => sortOrderOptions.value.find(option => option.id === search.value.sortOrder))
const sortOrder: Ref<SelectOption> = ref(currentSearchType.value ?? sortOrderOptions.value[0])

const minPrice: Ref<SelectOption> = ref(propertyType.value.value === 'rent'
  ? search.value.minprice
    ? rentMinPriceOptions.value.find(option => option.id === search.value.minprice) ?? rentMinPriceOptions.value[0]
    : rentMinPriceOptions.value[0] ?? rentMinPriceOptions.value[0]
  : search.value.minprice
    ? buyMinPriceOptions.value.find(option => option.id === search.value.minprice) ?? buyMinPriceOptions.value[0]
    : buyMinPriceOptions.value[0]) ?? buyMinPriceOptions.value[0]

const maxPrice: Ref<SelectOption> = ref(propertyType.value.value === 'rent'
  ? search.value.maxprice
    ? rentMaxPriceOptions.value.find(option => option.id === search.value.maxprice) ?? rentMaxPriceOptions.value[rentMaxPriceOptions.value.length - 1]
    : rentMaxPriceOptions.value[rentMaxPriceOptions.value.length - 1] ?? rentMaxPriceOptions.value[rentMaxPriceOptions.value.length - 1]
  : search.value.maxprice
    ? buyMaxPriceOptions.value.find(option => option.id === search.value.maxprice) ?? buyMaxPriceOptions.value[buyMaxPriceOptions.value.length - 1]
    : buyMaxPriceOptions.value[buyMaxPriceOptions.value.length - 1]) ?? buyMaxPriceOptions.value[buyMaxPriceOptions.value.length - 1]

// watch propertyType buy/rent and reset the min and max price options on change
watch (propertyType, () => {
  if (propertyType.value.value === 'rent') {
    minPrice.value = rentMinPriceOptions.value[0]
    maxPrice.value = rentMaxPriceOptions.value[rentMaxPriceOptions.value.length - 1]
  }
  else {
    minPrice.value = buyMinPriceOptions.value[0]
    maxPrice.value = buyMaxPriceOptions.value[buyMaxPriceOptions.value.length - 1]
  }
})

// a function to scroll to the top of the page very quickly and smoothly
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

// the modal
// const isOpen = ref(false)

// the SUBMIT function
// ------------------------------------
const router = useRouter()
const searchNow = async () => {
  await searchStore.setSearch({
    mapSearch: false,
    location: selectedLocation.value?.value ?? undefined,
    buyRent: propertyType.value.value ?? undefined,
    minprice: minPrice.value.id ?? undefined,
    maxprice: maxPrice.value.id ?? undefined,
  })
  emit('closeModal')
  router.push('/search')
  scrollToTop()
}

// update the sort order
const updateSortOrder = async () => {
  await searchStore.setSearch({
    sortOrder: sortOrder.value.id ?? undefined,
  })
  router.push('/search')
  scrollToTop()
}

// watch the sortOrder and update the sort order
watch(sortOrder, () => {
  updateSortOrder()
})

// browse latest
const browseLatest = async () => {
  await searchStore.setSearch({
    mapSearch: false,
    location: undefined,
    minprice: undefined,
    maxprice: undefined,
    // sortOrder: 'uploaded desc',
    sortOrder: 'closest',
  })
  emit('closeModal')
  router.push('/search')
  scrollToTop()
}

// press tab
const pressTab = () => {
  document.getElementById('location-input')?.blur()
}
</script>

<template>
  <div class="">
    <div class="">
      <form
        class="sm:flex gap-4"
        @submit.prevent="searchNow"
      >
        <div class="buy-rent flex flex-shrink mb-2">
          <RadioGroup id="buy-rent" v-model="propertyType" class="mt-2">
            <div class="grid grid-cols-2 gap-1">
              <RadioGroupOption
                v-for="option in options"
                :id="`buy-rent-options-${option.name}`"
                :key="option.name"
                v-slot="{ active, checked }" as="template"
                :value="option"
              >
                <div
                  class="cursor-pointer focus:outline-none flex items-center justify-center rounded-md py-2 sm:py-3 px-3 sm:px-4 text-sm sm:text-base font-semibold flex-1"
                  :class="[
                    active ? 'ring-2 ring-forest-600 ring-offset-2' : '',
                    checked ? 'bg-forest-600 text-white hover:bg-forest-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-700 hover:bg-gray-50',
                  ]"
                >
                  <RadioGroupLabel :id="`buy-rent-${option.name}`" as="span">
                    {{ option.name }}
                  </RadioGroupLabel>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div class="lg:w-72 relative z-20">
          <Combobox id="location" v-model="selectedLocation" nullable as="div" class="mb-2 md:mb-0">
            <ComboboxLabel id="location-label" class="sr-only">
              Location name
            </ComboboxLabel>
            <div class="relative mt-2 rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <Icon name="mdi:map-marker" class="h-6 w-6 text-forest-600" aria-hidden="true" />
              </div>
              <ComboboxInput
                id="location-input"
                class="block w-full text-base rounded-md border-0 py-3 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-forest-600 sm:leading-6"
                :display-value="(l: any) => l?.label"
                placeholder="Location"
                aria-autocomplete="none"
                autocomplete="off"
                @change="query = $event.target.value;"
                @input="getPlaces"
              />

              <ComboboxButton v-if="selectedLocation" id="location-clear" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <Icon name="ion:close" class="h-5 w-5 text-gray-400" aria-hidden="true" @click="selectedLocation = null" />
              </ComboboxButton>
              <ComboboxButton v-else id="location-clear" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <Icon name="ion:chevron-down" class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>

              <ComboboxOptions
                v-if="filteredLocations.length > 0"
                id="location-options"
                class="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <!-- max of 3 options -->
                <ComboboxOption
                  v-for="location in filteredLocations.slice(0, 3)"
                  :id="location.label"
                  :key="location.label"
                  v-slot="{ active, selected }" :value="location"
                  as="template"
                  @click="pressTab()"
                >
                  <li class="relative cursor-default select-none py-2 pl-3 pr-9" :class="[active ? 'bg-forest-600 text-white' : 'text-gray-900']">
                    <span class="block truncate" :class="[selected && 'font-semibold']">
                      {{ location.label }}
                    </span>

                    <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4" :class="[active ? 'text-white' : 'text-forest-600']">
                      <Icon name="ic:baseline-check" class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </div>
          </Combobox>
          <p id="email-description" class="mt-2 text-xs text-gray-400 hidden md:block mb-0">
            Start typing a location...
          </p>
        </div>

        <template v-if="route.fullPath === '/search'">
          <div>
            <Listbox id="min-price" v-model="minPrice" as="div" class="min-w-32">
              <ListboxLabel id="min-price-label" class="sr-only">
                Minimum Price
              </ListboxLabel>
              <div class="relative mt-2">
                <ListboxButton
                  id="min-price-button"
                  class="relative w-full font-normal cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-forest-600 text-base sm:leading-6"
                >
                  <span class="block truncate">{{ minPrice.text }}</span>
                  <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <Icon name="ion:chevron-down" class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions
                    id="min-price-options"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      v-for="price in (propertyType.value === 'rent' ? rentMinPriceOptions : buyMinPriceOptions)"
                      :id="price.id"
                      :key="price.id"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="price"
                    >
                      <li class="relative cursor-default select-none py-2 pl-8 pr-4" :class="[active ? 'bg-forest-600 text-white' : 'text-gray-900']">
                        <span class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">{{ price.text }}</span>

                        <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-forest-600']">
                          <Icon name="ion:checkmark-sharp" class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <p class="mt-2 text-xs text-gray-400 hidden md:block mb-0">
              Min Price
            </p>
          </div>
          <div>
            <Listbox id="max-price" v-model="maxPrice" as="div" class="min-w-32">
              <ListboxLabel class="sr-only">
                Maximum Price
              </ListboxLabel>
              <div class="relative mt-2">
                <ListboxButton
                  id="max-price-button"
                  class="relative w-full font-normal cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-forest-600 text-base sm:leading-6"
                >
                  <span class="block truncate">{{ maxPrice.text }}</span>
                  <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <Icon name="ion:chevron-down" class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions
                    id="max-price-options"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      v-for="price in (propertyType.value === 'rent' ? rentMaxPriceOptions : buyMaxPriceOptions)"
                      :id="`max-price-${price.id}`"
                      :key="price.id"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="price"
                    >
                      <li class="relative cursor-default select-none py-2 pl-8 pr-4" :class="[active ? 'bg-forest-600 text-white' : 'text-gray-900']">
                        <span class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">{{ price.text }}</span>

                        <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-forest-600']">
                          <Icon name="ion:checkmark-sharp" class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <p class="mt-2 text-xs text-gray-400 hidden md:block mb-0">
              Max Price
            </p>
          </div>
        </template>

        <div class="relative mt-2">
          <button type="submit" class="w-full lg:auto justify-center inline-flex items-center gap-x-1.5 rounded-md bg-forest-600 px-3.5 py-3 text-base font-semibold text-white shadow-sm hover:bg-forest-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-forest-600">
            Search
            <Icon name="mdi:search" class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </form>
      <div
        v-if="route.fullPath === '/search'"
        class="form-extras gap-2 lg:flex mt-8 lg:mt-6"
      >
        <!-- Sort Order -->
        <Listbox id="sort-order" v-model="sortOrder" as="div" class="min-w-40">
          <ListboxLabel class="sr-only">
            Sort Order
          </ListboxLabel>
          <div class="relative mt-2">
            <ListboxButton
              id="sort-order-button"
              class="relative w-full font-normal cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-forest-600 text-sm sm:leading-6"
            >
              <span class="block truncate">{{ sortOrder.text }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <Icon name="ion:chevron-down" class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions
                id="sort-order-options"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-for="price in sortOrderOptions"
                  :id="`sort-order-${price.id}`"
                  :key="price.id"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="price"
                >
                  <li class="relative cursor-default select-none py-2 pl-8 pr-4" :class="[active ? 'bg-forest-600 text-white' : 'text-gray-900']">
                    <span class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">{{ price.text }}</span>

                    <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-forest-600']">
                      <Icon name="ion:checkmark-sharp" class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>

        <!-- Currency Switcher -->
        <Listbox id="currency" v-model="usersSelectedCurrency" as="div" class="min-w-20">
          <ListboxLabel class="sr-only">
            Currency
          </ListboxLabel>
          <div class="relative mt-2">
            <ListboxButton
              id="currency-button"
              class="relative w-full font-normal cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-forest-600 text-sm sm:leading-6"
            >
              <span class="block truncate">{{ usersSelectedCurrency.text }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <Icon name="ion:chevron-down" class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions
                id="currency-options"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-for="currency in currencyOptions"
                  :id="`currency-${currency.id}`"
                  :key="currency.id"
                  v-slot="{ active, selected }"
                  as="template"
                  :value="currency"
                >
                  <li class="relative cursor-default select-none py-2 pl-8 pr-4" :class="[active ? 'bg-forest-600 text-white' : 'text-gray-900']">
                    <span class="block truncate" :class="[selected ? 'font-semibold' : 'font-normal']">{{ currency.text }}</span>

                    <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-1.5" :class="[active ? 'text-white' : 'text-forest-600']">
                      <Icon name="ion:checkmark-sharp" class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
        <!-- register for email alerts -->
        <div class=" mt-2">
          <UiBtnSecondary
            to="/property-services/buy"
            class="mt-2 sm:mt-0"
            variant="outline"
            size="sm"
            color="gray"
            rounded="none"
          >
            <Icon name="mdi:email" class="h-4 w-4 text-gray-700" aria-hidden="true" />
            Register for Email Alerts
          </UiBtnSecondary>
        </div>
      </div>

      <div v-if="route.fullPath === '/search'" class="mt-10">
        <UiBtn
          variant="link"
          :padded="false"
          label="Browse Latest"
          color="forest"
          size="sm"
          @click="browseLatest()"
        />
      </div>
    </div>
  </div>
</template>
