import type { LocationSuggestion } from '@/types'

export function useMapTiler() {
  const getSuggestions = async (searchString: string) => {
    const config = useRuntimeConfig()

    const baseUrl = 'https://api.maptiler.com/geocoding'
    const apiKey = config.public.mapTiler.apiKey
    // bounding box west, south, east, north
    // [-2.5, 51.5],
    // [-1.0, 52.4],
    const bbox = '-2.5,51.5,-1.0,52.4'
    const proximity = '-1.8,51.8'
    const excludedFeatureTypes = [
      'country',
      'address',
      'poi',
      'joint_municipality',
      'joint_submunicipality',
      'place',
      // 'municipality',
      'municipal_district',
    ]

    const url = `${baseUrl}/${searchString}.json?key=${apiKey}&bbox=${bbox}&proximity=${proximity}&language=en&limit=3&types=${excludedFeatureTypes.join(',')}&excludeTypes=true`

    try {
      const res: any = await $fetch(url, {
        method: 'GET',
      })
      return res
    }
    catch (error) {
      console.error('Error fetching suggestions from MapTiler', error)
      return []
    }
  }

  function milesToDegrees(distanceInMiles: number): { latitudeDegrees: number, longitudeDegrees: number } {
    // Constants
    const milesPerDegreeLatitude = 49.71 // Approximation at 52 degrees north
    const milesPerDegreeLongitude = 69 // Approximation

    // Calculate degrees for latitude
    const latitudeDegrees = distanceInMiles / milesPerDegreeLatitude

    // Calculate degrees for longitude
    const longitudeDegrees = distanceInMiles / milesPerDegreeLongitude

    return { latitudeDegrees, longitudeDegrees }
  }

  const getLocationSuggestions = async (search: string): Promise<LocationSuggestion[] | []> => {
    if (!search || search.length < 1)
      return [] // don't search until at least 1 character

    // get the location suggestions from the api
    const suggestions = await getSuggestions(search)
    if (!suggestions || !suggestions.features || suggestions.features?.length === 0)
      return []

    // // console.log('suggestions', suggestions)
    return suggestions.features.map((suggestion: any) => {
      // the vale of the v-model for location is the following object
      return {
        label: suggestion.text,
        value: {
          text: suggestion.text,
          // the bounding box is used to set the map view (we expand it slightly to make sure the marker is visible)
          n: suggestion.bbox[3] + (milesToDegrees(2).latitudeDegrees),
          s: suggestion.bbox[1] - (milesToDegrees(2).latitudeDegrees),
          e: suggestion.bbox[2] + (milesToDegrees(2).longitudeDegrees),
          w: suggestion.bbox[0] - (milesToDegrees(2).longitudeDegrees),
        },
      }
    })
  }

  return {
    getLocationSuggestions,
    getSuggestions,
  }
}
